import React, {useEffect, useRef, useState} from 'react';
import ReactPaginate from 'react-paginate';
import {CSVLink} from 'react-csv';
import moment from 'moment';
import jwt_decode from 'jwt-decode';

import Context from '../../context';
import OrderTableRow from '../Rows/OrderTableRow';
import Loader from '../Loader';
import Nav from '../Nav';
import UploadStockModal from '../Modals/UploadStockModal';
import Role from '../../tools/role';

import useSearchPreparationOrder from '../../tools/useSearchPreparationOrder';
import useGetUnassignmentReasons from '../../tools/useGetUnassignmentReasons';
import useGetChannels from '../../tools/useGetChannels';
import useGetConfigData from '../../tools/useGetConfigData';
import {GlamitOmsStates} from '../../tools/utils/omsStateUtils';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';

const FILTERS = {
    SEARCH_BY_INCREMENT_ID: {
        key: 'SEARCH_BY_INCREMENT_ID',
        label: 'Nro. Pedido',
    },
    SEARCH_BY_EXTERNAL_ID: {
        key: 'SEARCH_BY_EXTERNAL_ID',
        label: 'Nro. Plataforma',
    },
    SEARCH_BY_DNI: {
        key: 'SEARCH_BY_DNI',
        label: 'Documento',
    },
};
const DATE_FROM_DAYS_OFFSET = 60;
const DATE_RANGE_MAX_DAYS = 365;
const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;

function Preparation() {
    const token = localStorage.getItem('token');
    if (!token || token === 'null') {
        window.location.href = `${window.location.origin}/login`;
    }

    Role(['PICKUP', 'ADMIN', 'ADMINMARCA']);
    const initialDateFrom = new Date(new Date().setDate(new Date().getDate() - DATE_FROM_DAYS_OFFSET)).toISOString().slice(0, 10);
    const initialDateTo = new Date(new Date().setDate(new Date().getDate())).toISOString().slice(0, 10);

    const style = window.document.createElement('link');
    style.href = `${process.env.PUBLIC_URL}/css/Preparation.css`;
    style.rel = 'stylesheet';
    style.async = true;
    window.document.head.appendChild(style);

    // useStates
    const [role, setRole] = useState('PICKUP');
    const [store, setStore] = useState(localStorage.getItem('store_id'));
    const [getPickupChannel, setGetPickupChannel] = useState(false);
    const [clearOrder, setClearOrder] = useState(1);
    const [clearRowOrder, setClearRowOrder] = useState(1);
    const [dateFrom, setDateFrom] = useState(initialDateFrom);
    const [dateTo, setDateTo] = useState(initialDateTo);
    const DateFrom = useInputDateFrom(dateFrom);
    const DateTo = useInputDateTo(dateTo);
    const search = useInputSearch('');
    const [action, setAction] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [mySidebar, setMySidebar] = useState(false);
    const [searchDateState, setSearchDateState] = useState(false);
    const [searchStateState, setSearchStateState] = useState(false);
    const [searchChannelState, setSearchChannelState] = useState(false);
    const {ShippingMethods} = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [incrementId, setIncrementId] = useState('');
    const [externalId, setExternalId] = useState('');
    const [isShowReload, setIsShowReload] = useState(false);
    const [shippingFilter, setShippingFilter] = useState([]);
    const [stateFilter, setStateFilter] = useState([]);
    const [channelsFilter, setChannelsFilter] = useState([]);
    const [stateOrders, setStateOrders] = useState(GlamitOmsStates);
    const [textModal, setTextModal] = useState('');
    const [orderList, setOrderList] = useState([]);
    const [useOperator, setUseOperator] = useState(false);
    const [validatorNumbers, setValidatorNumbers] = useState(false);
    const [dni, setDNI] = useState('');
    const [localPageNumber, setLocalPageNumber] = useState(1);
    const [isValidRole, setIsValidRole] = useState(false);
    const [isOpenUploadStockModal, setIsOpenUploadStockModal] = useState(false);
    const [transactionData, setTransactionData] = useState([]);
    const [activeFilter, setActiveFilter] = useState(
        FILTERS.SEARCH_BY_INCREMENT_ID,
    );

    // useRefs
    const csvLink = useRef();
    const orderSearchInputRef = useRef(null);

    // customHooks
    const {unassignmentReasons} = useGetUnassignmentReasons();
    const {channels} = useGetChannels(store, 1);
    const {configData} = useGetConfigData(1);
    const {orders, loading, error, totalPages, totalOrders} =
        useSearchPreparationOrder(
            dateFrom,
            dateTo,
            clearOrder,
            clearRowOrder,
            externalId,
            incrementId,
            dni,
            getPickupChannel,
            localPageNumber,
            stateFilter,
            channelsFilter,
        );

    // useEffects
    useEffect(() => {
        let userActivityTimeout;
        const focusOrderSearchInput = () => {
            if (orderSearchInputRef.current) {
                orderSearchInputRef.current.focus();
            }
        };

        const handleUserActivity = () => {
            clearTimeout(userActivityTimeout);
            userActivityTimeout = setTimeout(() => focusOrderSearchInput(), 5000);
        };

        focusOrderSearchInput();
        ['mouseup', 'keyup', 'click'].forEach(event => document.addEventListener(event, handleUserActivity));
        return () => ['mouseup', 'keyup', 'click'].forEach(event => document.removeEventListener(event, handleUserActivity))
    }, []);

    useEffect(() => {
        const role = localStorage.getItem('role');
        const matchedRole = ['ADMINMARCA', 'ADMIN'].find(validRole => role.includes(validRole));
        if (matchedRole) {
            setRole(matchedRole);
            localStorage.setItem('PickupChannel', '');
        }
    }, []);

    useEffect(() => {
        const popupOperator = configData.find(
            (config) => config.path === 'popup_operator',
        );
        setUseOperator(popupOperator?.value || false);
    }, [configData]);

    useEffect(() => {
        const width = mySidebar ? '340px' : '0';
        const marginLeft = mySidebar ? '360px' : '10px';
        document.getElementById('mySidebar').style.width = width;
        document.getElementById('main').style.marginLeft = marginLeft;
    }, [mySidebar]);

    useEffect(() => setOrderList(orders), [stateFilter, shippingFilter, channelsFilter, orders, clearOrder]);

    useEffect(() => {
        document.getElementById('searchDate').style.maxHeight = searchDateState ? '1800px' : '0';
        document.getElementById('searchDateChevron').classList.toggle('filter-selected', searchDateState);
    }, [searchDateState]);

    useEffect(() => {
        document.getElementById('searchState').style.maxHeight = searchStateState ? '1800px' : '0';
        document.getElementById('searchStateChevron').classList.toggle('filter-selected', searchStateState);
    }, [searchStateState]);

    useEffect(() => {
        if (['ADMINMARCA', 'ADMIN'].includes(role)) {
            document.getElementById('searchChannel').style.maxHeight = searchChannelState ? '1800px' : '0';
            document.getElementById('searchChannelsChevron').classList.toggle('filter-selected', searchChannelState);
        }
    }, [searchChannelState]);

    useEffect(() => handleSearchOrder(), [activeFilter]);

    useEffect(() => {
        setValidatorNumbers(false);
        if (search.value().length !== 0) {
            const regExp = /^[1-9]\d*(-\d+)?$/;
            if (!regExp.test(search.value())) {
                setValidatorNumbers(true);
            }
        }
    }, [search]);

    useEffect(() => {
        const decodedToken = jwt_decode(token);
        const validRoles = [`ROLE_${store}_OMNI_ADMIN`, 'ROLE_GLAMIT_ADMIN'];
        if (decodedToken['cognito:groups'].some(group => validRoles.includes(group))) {
            setIsValidRole(true);
        }
    }, [isValidRole]);

    useEffect(() => submitDateHandler(), [DateFrom.value(), DateTo.value()]);

    // functions
    function openNav() {
        setMySidebar(true);
    }

    function closeNav() {
        setMySidebar(false);
    }

    function clearToStart() {
        setClearOrder((clearOrder) => clearOrder + 1);
    }

    function onNext({selected}) {
        setLocalPageNumber(selected + 1);
        clearToStart();
    }

    function useInputDateFrom(defaultValue = '') {
        const [value, setValue] = useState(defaultValue);
        return {
            bind: {
                value,
                onChange: (event) => setValue(event.target.value),
            },
            clear: () => setValue(''),
            value: () => value,
            setDefaultValue: () => setValue(defaultValue),
        };
    }

    function useInputDateTo(defaultValue = '') {
        const [value, setValue] = useState(defaultValue);
        return {
            bind: {
                value,
                onChange: (event) => setValue(event.target.value),
            },
            clear: () => setValue(''),
            value: () => value,
            setDefaultValue: () => setValue(defaultValue),
        };
    }

    function useInputSearch(defaultValue = '') {
        const [value, setValue] = useState(defaultValue);
        return {
            bind: {
                value,
                onChange: (event) => setValue(event.target.value),
            },
            clear: () => setValue(''),
            value: () => value,
            setDefaultValue: () => setValue(defaultValue),
        };
    }

    function searchChannels() {
        if (searchChannelState) {
            setSearchChannelState(false);
        } else {
            setSearchChannelState(true);
            setSearchStateState(false);
            setSearchDateState(false);
        }
    }

    function addChannelsFilter(channelId) {
        if (!channelId || channelId === 'null') {
            return;
        }
        if (channelsFilter.indexOf(channelId) === -1) {
            setChannelsFilter((prevChannelsFilter) => [
                ...prevChannelsFilter,
                channelId,
            ]);
            clearToStart();
        }
    }

    function showShippingFilter(filter) {
        for (const method of ShippingMethods) {
            if (method['code'] === filter) {
                return method['name'];
            }
        }
    }

    function removeShippingFilter(event) {
        const filter = event.target.attributes.getNamedItem('data-id').value;
        setShippingFilter(shippingFilter.filter((item) => item !== filter));
    }

    function removeChannelsFilter(filter) {
        setChannelsFilter(channelsFilter.filter((item) => item !== filter));
        clearToStart();
    }

    function addStateFilter(code) {
        setStateFilter((prevStateFilter) => {
            let filtered = prevStateFilter;
            if (code === 'RMA_REQUEST_D') {
                filtered = prevStateFilter.filter((s) => s !== 'RMA_REQUEST_C');
            } else if (code === 'RMA_REQUEST_C') {
                filtered = prevStateFilter.filter((s) => s !== 'RMA_REQUEST_D');
            }
            if (!filtered.includes(code)) {
                filtered = [...filtered, code];
            }
            return filtered;
        });
        clearToStart();
    }

    function showStateFilter(filter) {
        for (const stateOrder of stateOrders) {
            if (stateOrder['code'] === filter) {
                return stateOrder['name'];
            }
        }
    }

    function showChannelFilter(filter) {
        for (const channel of channels) {
            if (channel['channel_id'] === filter) {
                return channel['channel_name'];
            }
        }
    }

    function removeStateFilter(filter) {
        setStateFilter(stateFilter.filter((item) => item !== filter));
        clearToStart();
    }

    function clearAllFilters() {
        DateFrom.setDefaultValue();
        DateTo.setDefaultValue();
        DateFrom.bind.onChange({ target: { value: initialDateFrom } });
        DateTo.bind.onChange({ target: { value: initialDateTo } });
        setDateFrom(initialDateFrom);
        setDateTo(initialDateTo);
        setStateFilter([]);
        setShippingFilter([]);
        setChannelsFilter([]);
        reloadSearch();
        clearToStart();
    }

    function reloadSearch() {
        setIsShowReload(false);
        setIncrementId('');
        setExternalId('');
        setDNI('');
        search.clear();
        clearToStart();
    }

    function hasSearchValue() {
        return search.value().length > 0 && !validatorNumbers;
    }

    const handleSearchOrder = () => {
        if (hasSearchValue()) {
            runOrderSearch();
        }
    };

    const runOrderSearch = () => {
        setDNI('');
        setExternalId('');
        setIncrementId('');
        const searchValue = search.value();
        switch (activeFilter.key) {
            case 'SEARCH_BY_INCREMENT_ID':
                setIncrementId(searchValue);
                break;
            case 'SEARCH_BY_EXTERNAL_ID':
                setExternalId(searchValue);
                break;
            case 'SEARCH_BY_DNI':
                setDNI(searchValue);
                break;
        }
        setLocalPageNumber(1);
        setIsShowReload(true);
    };

    function submitDateHandler() {
        const dateFromValue = DateFrom.value();
        const dateToValue = DateTo.value();
        const diffInDays = (new Date(dateToValue) - new Date(dateFromValue)) / MILLISECONDS_PER_DAY;

        if (diffInDays > DATE_RANGE_MAX_DAYS) {
            DateFrom.setDefaultValue();
            DateTo.setDefaultValue();
            alert(`Por favor ingresar un rango de fechas que contenga menos de ${DATE_RANGE_MAX_DAYS} días`);
            return;
        }

        setDateFrom(dateFromValue);
        setDateTo(dateToValue);
        clearToStart();
    }

    function actionSelectChange(event) {
        setAction(event.target.value);
    }

    function dynamicSort(property) {
        const sortOrder = property.startsWith('-') ? -1 : 1;
        const sortedProperty = property.replace(/^-/, '');

        return (a, b) => {
            if (a[sortedProperty] < b[sortedProperty]) return -1 * sortOrder;
            if (a[sortedProperty] > b[sortedProperty]) return 1 * sortOrder;
            return 0;
        };
    }

    function getAllChecked() {
        if (!action || action === 'null') {
            alert('Debe seleccionar una acción');
            return;
        }

        if (action === 'uploadStock') {
            setIsOpenUploadStockModal(true);
        }

        const handleAllChecked = document.getElementById('handleAllChecked');
        if (handleAllChecked) handleAllChecked.checked = false;
        showModal(<Loader/>);

        const checkedArr = Array.from(document.getElementsByClassName('checkboxorder'))
            .filter((checkbox) => checkbox.checked)
            .map((checkbox) => checkbox.value);

        if (checkedArr.length === 0) {
            alert('Debe seleccionar al menos una orden');
            setIsOpenModal(false);
            return;
        }

        orders.forEach((order) => {
            if (checkedArr.includes(order.order_id)) {
                order.skus.forEach((sku) => {
                    setTransactionData((prevTransactionData) => [
                        ...prevTransactionData,
                        {
                            nro_pedido: order.increment_id,
                            sku: sku.sku,
                            color: sku.sku_color,
                            talle: sku.sku_size,
                            cantidad: sku.qty,
                            'nombre SKU': sku.sku_name,
                        },
                    ]);
                });
            }
        });

        setIsOpenModal(false);

        setTimeout(() => {
            if (csvLink.current && csvLink.current?.link) {
                csvLink.current.link.click();
            }
        }, 1000);
    }

    function handleAllChecked() {
        setIsChecked(!isChecked);
    }

    function showModal(text) {
        setIsOpenModal(true);
        setTextModal(text);
    }

    function closePopup() {
        setIsOpenModal(false);
    }

    function searchDate() {
        if (searchDateState) {
            setSearchDateState(false);
        } else {
            setSearchDateState(true);
            setSearchStateState(false);
            setSearchChannelState(false);
        }
    }

    function searchState() {
        if (searchStateState) {
            setSearchStateState(false);
        } else {
            setSearchStateState(true);
            setSearchDateState(false);
            setSearchChannelState(false);
        }
    }

    return (
        <Context.Provider
            value={{
                clearToStart,
                showModal,
                setIsOpenUploadStockModal,
                isChecked,
                unassignmentReasons,
                channels,
                configData,
            }}
        >
            {isOpenModal === true && (
                <div
                    className='modal'
                    tabIndex='-1'
                    role='dialog'
                    style={{display: 'block'}}
                >
                    <div className='modal-dialog' role='document'>
                        <div className='modal-content'>
                            <div className='modal-body'>
                                <p>{textModal}</p>
                            </div>
                            <div className='modal-footer'>
                                <button
                                    type='button'
                                    className='btn btn-secondary'
                                    data-dismiss='modal'
                                    onClick={closePopup}
                                >
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {isOpenUploadStockModal === true && <UploadStockModal/>}

            {/* Barra de navegación */}
            <div className='page-header'>
                <Nav/>
            </div>

            {/* Contenido */}
            <div className='page-container'>
                {/* Barra lateral de filtros */}
                <div className='menu-container' id='mySidebar'>
                    <a className='closebtn' onClick={closeNav}>
                        {' '}
                        <svg
                            width='1em'
                            height='1em'
                            viewBox='0 0 16 16'
                            className='bi bi-x'
                            fill='currentColor'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                fillRule='evenodd'
                                d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'
                            />
                        </svg>
                    </a>

                    <div className='menu-scroller' id='scroller'>
                        <div className='filters-title'>Filtros</div>
                        {/* Filtros aplicados */}
                        <div className='active-filters-container'>
                            {shippingFilter.map((filter, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='active-filter-box'
                                        data-id={filter}
                                    >
                                        <div className='display-table'>
                                            <div className='active-filter-text'>
                                                {showShippingFilter(filter)}
                                            </div>
                                        </div>
                                        <div className='display-table' data-id={filter}>
                                            <div
                                                className='active-filter-close'
                                                data-id={filter}
                                                onClick={removeShippingFilter}
                                            >
                                                <svg
                                                    data-id={filter}
                                                    width='1em'
                                                    height='1em'
                                                    viewBox='0 0 16 16'
                                                    className='bi bi-x'
                                                    fill='currentColor'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                >
                                                    <path
                                                        fillRule='evenodd'
                                                        d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                            {stateFilter.map((filter, index) => (
                                <div key={`${index}-${filter}`} className='active-filter-box'>
                                    <div className='display-table'>
                                        <div className='active-filter-text'>
                                            {showStateFilter(filter)}
                                        </div>
                                    </div>
                                    <div className='display-table' data-id={filter}>
                                        <div
                                            className='active-filter-close'
                                            data-id={filter}
                                            onClick={() => removeStateFilter(filter)}
                                        >
                                            <svg
                                                data-id={filter}
                                                width='1em'
                                                height='1em'
                                                viewBox='0 0 16 16'
                                                className='bi bi-x'
                                                fill='currentColor'
                                                xmlns='http://www.w3.org/2000/svg'
                                            >
                                                <path
                                                    fillRule='evenodd'
                                                    d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {channelsFilter.map((filter, index) => (
                                <div key={index} className='active-filter-box'>
                                    <div className='display-table' data-id={filter}>
                                        <div className='active-filter-text'>
                                            {showChannelFilter(filter)}
                                        </div>
                                    </div>
                                    <div className='display-table' data-id={filter}>
                                        <div
                                            className='active-filter-close'
                                            data-id={filter}
                                            onClick={() => removeChannelsFilter(filter)}
                                        >
                                            <svg
                                                data-id={filter}
                                                width='1em'
                                                height='1em'
                                                viewBox='0 0 16 16'
                                                className='bi bi-x'
                                                fill='currentColor'
                                                xmlns='http://www.w3.org/2000/svg'
                                            >
                                                <path
                                                    fillRule='evenodd'
                                                    d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div className='active-filter-box'>
                                <div className='display-table'>
                                    <div className='active-filter-text'>
                                        Desde {moment.utc(dateFrom).format('DD/MM/YYYY')}
                                    </div>
                                </div>
                            </div>

                            <div className='active-filter-box'>
                                <div className='display-table'>
                                    <div className='active-filter-text'>
                                        Hasta {moment.utc(dateTo).format('DD/MM/YYYY')}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='clear-filters' onClick={clearAllFilters}>
                            Limpiar filtros
                        </div>

                        {/* Filtros */}
                        <div className='filters-container'>
                            {/* Filtro por fechas */}
                            <div className='filter-container'>
                                <div
                                    className='filter-title-grid'
                                    id='searchDateChevron'
                                    onClick={searchDate}
                                >
                                    <div className='filter-title-chevron'>
                                        <svg
                                            width='1em'
                                            height='1em'
                                            viewBox='0 0 16 16'
                                            className='bi bi-chevron-right'
                                            fill='currentColor'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path
                                                fillRule='evenodd'
                                                d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'
                                            />
                                        </svg>
                                    </div>
                                    <div className='filter-title-text'>Fecha de creación</div>
                                </div>

                                <div className='filter-options-container' id='searchDate'>
                                    <div className='filter-option'>
                                        <div>
                                            <span className='filter-option-text-date filter-option-selected'>
                                                Desde
                                            </span>
                                            <DatePicker
                                                selected={
                                                    DateFrom.value()
                                                        ? moment(DateFrom.value(), 'YYYY-MM-DD').toDate()
                                                        : null
                                                }
                                                onChange={(date) => {
                                                    if (!date) return;

                                                    const reformattedDate = moment.utc(date).format('YYYY-MM-DD');

                                                    if (DateTo.value() && moment(reformattedDate).isAfter(DateTo.value())) {
                                                        alert('La fecha "Desde" no puede ser posterior a la fecha "Hasta".');
                                                        return;
                                                    }

                                                    DateFrom.bind.onChange({target: {value: reformattedDate}});
                                                }}
                                                dateFormat="dd/MM/yyyy"
                                                locale="es"
                                                className="date-filter-input"
                                                placeholderText="Seleccione una fecha"
                                                maxDate={
                                                    DateTo.value()
                                                        ? moment(DateTo.value(), 'YYYY-MM-DD').toDate()
                                                        : moment.utc().toDate()
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='filter-option'>
                                        <div>
                                            <span className='filter-option-text-date filter-option-selected'>
                                                Hasta
                                            </span>
                                            <DatePicker
                                                selected={
                                                    DateTo.value() &&
                                                    moment.utc(DateTo.value(), 'YYYY-MM-DD', true).isValid()
                                                        ? moment(DateTo.value(), 'YYYY-MM-DD').toDate()
                                                        : null
                                                }
                                                onChange={(date) => {
                                                    if (!date) return;

                                                    const reformattedDate = moment.utc(date).format('YYYY-MM-DD');

                                                    if (DateFrom.value() && moment(reformattedDate).isBefore(DateFrom.value())) {
                                                        alert('La fecha "Hasta" no puede ser anterior a la fecha "Desde".');
                                                        return;
                                                    }

                                                    DateTo.bind.onChange({target: {value: reformattedDate}});
                                                }}
                                                dateFormat="dd/MM/yyyy"
                                                locale="es"
                                                className="date-filter-input"
                                                placeholderText="Seleccione una fecha"
                                                maxDate={moment.utc().toDate()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Filtro por estados */}
                            <div className='filter-container'>
                                <div
                                    className='filter-title-grid'
                                    id='searchStateChevron'
                                    onClick={searchState}
                                >
                                    <div className='filter-title-chevron'>
                                        <svg
                                            width='1em'
                                            height='1em'
                                            viewBox='0 0 16 16'
                                            className='bi bi-chevron-right'
                                            fill='currentColor'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path
                                                fillRule='evenodd'
                                                d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'
                                            />
                                        </svg>
                                    </div>
                                    <div className='filter-title-text'>Estados</div>
                                </div>

                                <div className='filter-options-container' id='searchState'>
                                    {stateOrders.map((orderState, index) => (
                                        <div
                                            key={index}
                                            className='filter-option'
                                            onClick={() => addStateFilter(orderState.code)}
                                        >
                                            <div>
                                                  <span
                                                      data-tag={orderState.code}
                                                      className={
                                                          stateFilter.length > 0 &&
                                                          stateFilter.indexOf(orderState.code) !== -1
                                                              ? 'filter-option-text filter-option-selected'
                                                              : 'filter-option-text'
                                                      }
                                                  >
                                                      {orderState.name !== 'Aprobado' && orderState.name}
                                                  </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Filtro por locales */}
                            {['ADMINMARCA', 'ADMIN'].includes(role) && (
                                <div className='filter-container'>
                                    <div
                                        className='filter-title-grid'
                                        id='searchChannelsChevron'
                                        onClick={searchChannels}
                                    >
                                        <div className='filter-title-chevron'>
                                            <svg
                                                width='1em'
                                                height='1em'
                                                viewBox='0 0 16 16'
                                                className='bi bi-chevron-right'
                                                fill='currentColor'
                                                xmlns='http://www.w3.org/2000/svg'
                                            >
                                                <path
                                                    fillRule='evenodd'
                                                    d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'
                                                />
                                            </svg>
                                        </div>
                                        <div className='filter-title-text'>Locales</div>
                                    </div>

                                    <div className='filter-options-container' id='searchChannel'>
                                        {channels.map((channel, index) => (
                                            <div
                                                key={index}
                                                className='filter-option'
                                                onClick={() => addChannelsFilter(channel.channel_id)}
                                            >
                                                <div>
                                                    <span
                                                        data-tag={channel.channel_id}
                                                        className={
                                                            channelsFilter.length > 0 &&
                                                            channelsFilter.indexOf(channel.channel_id) !== -1
                                                                ? 'filter-option-text filter-option-selected'
                                                                : 'filter-option-text'
                                                        }
                                                    >
                                                      {channel?.channel_name}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Grilla de pedidos */}
                <div className='main-container' id='main'>
                    <div className='title'>Listado de pedidos</div>
                    <div className='actions-container'>
                        <div className='search-container'>
                            <div className='row'>
                                <div className='col-10'>
                                    <a className='openbtn' onClick={openNav}>
                                        ☰ Ver filtros
                                    </a>
                                </div>
                            </div>

                            <div className='actions'>&nbsp;</div>

                            <div className='row'>
                                <div className='col-md-8 col-12'>
                                    <div className='input-group input-group-sm pl-0'>
                                        <input
                                            ref={orderSearchInputRef}
                                            id='searchInput'
                                            type='text'
                                            className='form-control'
                                            aria-label='...'
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                    event.preventDefault();
                                                    runOrderSearch();
                                                }
                                            }}
                                            {...search.bind}
                                        />
                                        <div className='input-group-btn'>
                                            <button
                                                type='button'
                                                className='btn btn-secondary dropdown-toggle btn-sm pb-0'
                                                data-toggle='dropdown'
                                                aria-haspopup='true'
                                                aria-expanded='false'
                                                style={{margin: '0 5px'}}
                                            >
                                                {activeFilter.label}
                                            </button>
                                            <button
                                                type='button'
                                                className='btn btn-primary btn-sm pb-0'
                                                onClick={() => handleSearchOrder()}
                                                disabled={!hasSearchValue()}
                                                style={{
                                                    cursor: hasSearchValue() ? 'pointer' : 'not-allowed',
                                                }}
                                            >
                                                <FontAwesomeIcon className='mr-1' icon={faSearch}/>
                                            </button>
                                            <div className='dropdown-menu dropdown-menu-right'>
                                                <button
                                                    className='dropdown-item'
                                                    type='button'
                                                    onClick={() =>
                                                        setActiveFilter(FILTERS.SEARCH_BY_INCREMENT_ID)
                                                    }
                                                >
                                                    Nro. Pedido
                                                </button>
                                                <button
                                                    className='dropdown-item'
                                                    type='button'
                                                    onClick={() =>
                                                        setActiveFilter(FILTERS.SEARCH_BY_EXTERNAL_ID)
                                                    }
                                                >
                                                    Nro. Plataforma
                                                </button>
                                                <button
                                                    className='dropdown-item'
                                                    type='button'
                                                    onClick={() => setActiveFilter(FILTERS.SEARCH_BY_DNI)}
                                                >
                                                    Documento
                                                </button>
                                            </div>
                                        </div>

                                        {isShowReload && (
                                            <div className='mt-1'>
                                                <svg
                                                    onClick={reloadSearch}
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='1em'
                                                    height='1em'
                                                    fill='currentColor'
                                                    className='bi bi-arrow-repeat'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path
                                                        d='M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z'/>
                                                    <path
                                                        fillRule='evenodd'
                                                        d='M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z'
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {validatorNumbers && search.value().length !== 0 && (
                                <div className='row'>
                                    <div className='col-12 mt-0 pt-0 pb-1'>
                                        <strong
                                            className='text-white p-1 mb-4 rounded bg-danger'
                                            style={{fontWeight: '500', fontSize: '0.8rem'}}
                                        >
                                            Sólo se permiten números
                                        </strong>
                                    </div>
                                </div>
                            )}

                            <div className='row d-block'>
                                {isShowReload &&
                                    search.value().length !== 0 &&
                                    orderList.length === 0 &&
                                    !validatorNumbers &&
                                    !loading && (
                                        <div className='col-12 mt-1'>
                                            <div
                                                className='p-1 alert alert-warning alert-warning-custom'
                                                style={{fontSize: '0.9rem'}}
                                                role='alert'
                                            >
                                                No se encontraron pedidos con los datos ingresados!
                                            </div>
                                        </div>
                                    )}
                            </div>
                            <div className='row'>
                                <div className='col-10'>
                                    <div className='mb-2' style={{fontSize: '0.8rem'}}>
                                        <span>Total de registros encontrados: {totalOrders}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {orders.length !== 0 && (
                            <div className='action-select-container'>
                                <div className='acdb'>
                                    <div className='actions'>Acciones</div>
                                    <select
                                        className='actions-select'
                                        onChange={actionSelectChange}
                                    >
                                        <option value='null'>Seleccione una opción</option>
                                        <option value='pickuplist'>
                                            Generar listado de pickeo
                                        </option>
                                        {isValidRole && (
                                            <option value='uploadStock'>
                                                Importar stocks desde CSV
                                            </option>
                                        )}
                                    </select>
                                    <button className='blue font-18' onClick={getAllChecked}>
                                        Ir
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='seleccionar-todo-container mt-3'>
                        {orders.length !== 0 && (
                            <div>
                                <div className='float-right revert'>
                                    <div className='container-check'>
                                        Orden: Fecha pedido ascendente
                                    </div>
                                </div>
                                <div className='float-left col-8 d-block'>
                                    <label className='container-check'>
                                        Seleccionar todos los pedidos
                                        <input
                                            type='checkbox'
                                            id='handleAllChecked'
                                            onClick={handleAllChecked}
                                        />
                                        <span className='checkmark'></span>
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>

                    {orderList.map((order, index) => {
                        return (
                            <OrderTableRow
                                role={role}
                                order={order}
                                key={order.order_id}
                                index={index}
                                useOperator={useOperator}
                            />
                        );
                    })}

                    <ReactPaginate
                        initialPage={0}
                        nextLabel='>'
                        onPageChange={onNext}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={1}
                        pageCount={totalPages}
                        previousLabel='<'
                        pageClassName='page-item'
                        pageLinkClassName='page-link'
                        previousClassName='page-item'
                        previousLinkClassName='page-link'
                        nextClassName='page-item'
                        nextLinkClassName='page-link'
                        breakLabel='...'
                        breakClassName='page-item'
                        breakLinkClassName='page-link'
                        containerClassName='pagination'
                        activeClassName='active'
                        renderOnZeroPageCount={null}
                    />

                    <div>{loading && <Loader/>}</div>
                    <div>{error && 'Error'}</div>
                </div>
            </div>

            <CSVLink
                data={transactionData.sort(dynamicSort('sku'))}
                separator={';'}
                filename={'export.csv'}
                target='_blank'
                ref={csvLink}
            />
        </Context.Provider>
    );
}

export default Preparation;